
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const phone = ref(null);
    const formData = ref({
      groupname: "",
    });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const page = ref<string>("");
    // const value = ref('');
    const registration = Yup.object().shape({
      groupname: Yup.string().required().label("Group Name"),
    });

    onMounted(() => {
      const data = route.params.id;
      if (data) {
        // getUser(id);
        // page.value = "Edit";
        setCurrentPageBreadcrumbs("Edit Group", []);
      } else {
        // page.value = "Add";
        setCurrentPageBreadcrumbs("Add Group", []);
      }
      // setCurrentPageBreadcrumbs("Add User", ["User"]);
      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        //formData.value = data
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      const id = route.params.id;
      if (id) {
        getUser(id);

        page.value = "Edit";
      } else {
        page.value = "Add";
      }
    });
    const getUser = (id) => {
      // var request = {
      //   url: `plan/${id}`,
      // };
      // store.dispatch(Actions.GET, request).then((data) => {
      //   if (data) {
      //     // console.log(data)
      //     formData.value = {
      //       name: data.data.name,
      //       type: data.data.type,
      //       user_count: data.data.user_count,
      //       price: data.data.price,
      //       description: data.data.description,
      //     };
      //   }
      // });
    };

    const onSubmitRegister = (values, { resetForm }) => {
      submitButton.value!.disabled = true;
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      loading.value = true;

      if (values.groupname.trim() == "") {
        Swal.fire({
          text: "Please fill all fields!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        var request = {
          url: "groups",
          data: {
            groupname: values.groupname,
          },
        };
        store
          .dispatch(Actions.POST, request)
          .then((data) => {
            if (data) {
              Swal.fire("Success", "Group added successfully!", "success");
              router.push({ name: "user-group-contact" });
            }
            loading.value = false;
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
      submitButton.value?.removeAttribute("data-kt-indicator");
      submitButton.value!.disabled = false;
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      phone,
      formData,
      page,
      getUser,
    };
  },
});
